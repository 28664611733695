import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {STORAGE_KEYS} from '../app.constants';
import {ITenant, Tenant} from '../models/tenant';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService {

  protected static tenantSubject: Subject<Tenant> = new Subject<Tenant>();

  private resourceUrl = `${environment.API_BASE_URL}/tenants`;

  constructor(
    private readonly http: HttpClient,
    private readonly storageService: StorageService,
    private translate: TranslateService
  ) {
  }

  findActivatedTrue(): Observable<ITenant[]> {
    return this.http.get<ITenant[]>(`${this.resourceUrl}/list`);
  }

  setTenant(tenant: ITenant) {
    this.translate.use(tenant.language);
    this.storageService.set(STORAGE_KEYS.TENANT_ID, tenant.id);
    this.storageService.set(STORAGE_KEYS.LANGUAGE, tenant.language);
    this.storageService.set(STORAGE_KEYS.TERMS, tenant.termsOfService);
    this.storageService.set(STORAGE_KEYS.PRIVACY_POLICY, tenant.privacyPolicy);
    this.storageService.set(STORAGE_KEYS.DATA_PRIVACY, tenant.dataPrivacy);
    this.storageService.set(STORAGE_KEYS.COUNTRY_ISO_CODE, tenant.countryIsoCode);
  }

  clearTenant() {
    this.storageService.remove(STORAGE_KEYS.TERMS_SELECT);
    this.storageService.remove(STORAGE_KEYS.ACCEPTED_TERMS);
    this.storageService.remove(STORAGE_KEYS.TENANT_ID);
    this.storageService.remove(STORAGE_KEYS.LANGUAGE);
    this.storageService.remove(STORAGE_KEYS.TERMS);
    this.storageService.remove(STORAGE_KEYS.PRIVACY_POLICY);
    this.storageService.remove(STORAGE_KEYS.COUNTRY_ISO_CODE);
    this.storageService.remove(STORAGE_KEYS.DATA_PRIVACY);
  }

  getTenant() {
    return this.storageService.get(STORAGE_KEYS.TENANT_ID);
  }

  get onChangeTenant() {
    return TenantService.tenantSubject.asObservable();
  }

}
