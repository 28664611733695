<ion-header mode="ios">
  <ion-toolbar mode="md">
    <ion-buttons slot="start">
      <ion-button (click)="onFirstButtonPressed()" *ngIf="isReturnable">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{title | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button class="control-button" (click)="onSecondButtonPressed()" [hidden]="!secondControlButtonName">
        <ion-icon slot="icon-only" name="{{secondControlButtonName}}"></ion-icon>
      </ion-button>
      <ion-button class="control-button" (click)="onThirdButtonPressed()" [hidden]="!thirdControlButtonName">
        <ion-icon slot="icon-only" name="{{thirdControlButtonName}}"></ion-icon>
      </ion-button>
      <ng-content></ng-content>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
