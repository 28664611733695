import { Component, OnInit, OnDestroy} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {HOME_PAGE, SCHEDULE_PAGE, STORAGE_KEYS} from 'src/app/app.constants';
import { ITenant } from 'src/app/models/tenant';
import { StorageService } from '../../services/storage.service';
import {NavigationEnd, Router} from '@angular/router';
import {ToastService} from '../../services/toast.service';
import { IonRouterOutlet } from '@ionic/angular';
import {ScheduleService} from '../../services/schedule.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.page.html',
  styleUrls: ['./terms.page.scss'],
})
export class TermsPage implements OnInit, OnDestroy {

  accepted = false;
  tenant: ITenant;
  isTermsOfService = false;
  isPrivacyPolicy = false;
  isDataPrivacy = false;
  navigationSubscription;
  buttonRejected = false;


  constructor(
      private readonly storageService: StorageService,
      private readonly router: Router,
      private readonly toastService: ToastService,
      private readonly translateService: TranslateService,
      private routerOutlet: IonRouterOutlet,
      private readonly scheduleService: ScheduleService
  ) {
    this.accepted = true;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });

    setTimeout(() => {
      this.accepted = false;
    }, 1000);

    this.scheduleService.checkSchedule().subscribe((res: any) => {
      if (!res.online) {
        this.router.navigateByUrl(SCHEDULE_PAGE);
      }
    }, (error: any) => {
      console.log(error);
    });

  }

  ngOnInit() {
    this.routerOutlet.swipeGesture = false;
    this.isTermsOfService = false;
    this.isPrivacyPolicy = false;
    this.isDataPrivacy = false;
    this.storageService.remove(STORAGE_KEYS.ACCEPTED_TERMS);
    this.translateService.use(this.storageService.get(STORAGE_KEYS.LANGUAGE));
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
      this.accepted = false;
    }
  }

  initialiseInvites() {
    this.buttonRejected = false;
    this.tenant = {
      id: this.storageService.get(STORAGE_KEYS.TENANT_ID),
      language: this.storageService.get(STORAGE_KEYS.LANGUAGE),
      termsOfService: this.checkHTTPS(this.storageService.get(STORAGE_KEYS.TERMS)),
      privacyPolicy: this.checkHTTPS(this.storageService.get(STORAGE_KEYS.PRIVACY_POLICY)),
      dataPrivacy: this.checkHTTPS(this.storageService.get(STORAGE_KEYS.DATA_PRIVACY))
    };

  }

  accept() {
    if (this.enableAcceptButton()) {
      this.accepted = true;
      this.buttonRejected = true;
      this.storageService.set(STORAGE_KEYS.ACCEPTED_TERMS, 'true');
      this.router.navigateByUrl(HOME_PAGE);
      this.isTermsOfService = false;
      this.isPrivacyPolicy = false;
      this.isDataPrivacy = false;
    }
    setTimeout(() => {
      this.accepted = false;
    }, 2000);
  }

  decline() {
    this.toastService.error(
        this.translateService.instant('terms.decline')
    );
    this.tenant = undefined;
    this.accepted = false;
    this.isTermsOfService = false;
    this.isPrivacyPolicy = false;
    this.isDataPrivacy = false;
    this.router.navigateByUrl('/decline');
  }

  enableAcceptButton() {
    if (this.tenant && this.tenant.dataPrivacy) {
      return this.isTermsOfService && this.isPrivacyPolicy && this.isDataPrivacy;
    } else {
      return this.isTermsOfService && this.isPrivacyPolicy;
    }
  }

  onAcceptAll() {
    if (this.isTermsOfService && this.isPrivacyPolicy && this.isDataPrivacy) {
      this.isTermsOfService = false;
      this.isDataPrivacy = false;
      this.isPrivacyPolicy = false;
    } else {
      this.isTermsOfService = true;
      this.isDataPrivacy = true;
      this.isPrivacyPolicy = true;
    }
  }

  checkHTTPS(link) {
    if (link && link.indexOf('https') === -1) {
      return `https://${link}`;
    }
    return link;
  }

}
