import { Injectable } from '@angular/core';
import { Client, Conversation } from '@twilio/conversations';
import { AttendanceService } from 'src/app/services/attendance.service';
import {BehaviorSubject, Observable} from 'rxjs';
import { DeviceLogService } from './device-log.service';

export class ChatMessage {
  text?: string;
  owner?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TwilioChatService {
  private twilloCliente: any;
  private newConversation: any;
  private joinedConversation: any;
  private readonly conversationSubject$ = new BehaviorSubject({});


  get conversationSubject(): Observable<any> {
    return this.conversationSubject$.asObservable();
  }

  constructor(
      private readonly deviceLogService: DeviceLogService, private attendanceService: AttendanceService) {
  }

  async init(accessToken: string, roomName: string, id: number): Promise<Conversation> {
    await this.startClient(accessToken);
    await this.startConversation(roomName);
    await this.joinConversation();
    await this.updateConversationSid(id);
    return await this.getConversation();

  }

  sendAndLogError(error: any, methodName: string) {
    this.sendMsgError(error);
  }

  async connect(accessToken: string, roomName: string) {
    this.twilloCliente = await this.startClient(accessToken);
    try {
      this.newConversation = await this.getConversationByRoomName(roomName);
      this.conversationSubject$.next(this.newConversation);
      return await this.getConversation();
    } catch (e) {
      this.sendAndLogError(e, 'connect');
    }
  }

  async leave() {
    if (!this.twilloCliente) {
      return;
    }

    try {
      await this.getConversation().leave();
      await this.getClient().shutdown();
    } catch (error) {
      this.sendAndLogError(error, 'leave');
    }
  }


  submitMessage(msg: string) {
    if (this.newConversation) {
      this.getConversation().sendMessage(msg).catch((error) => {
        this.sendAndLogError(error, 'submitMessage');
      });
    }
  }

  async submitMediaMessage(mediaMsg: any) {
    if (this.newConversation) {
      return this.getConversation().sendMessage(mediaMsg).catch((error) => {
        this.sendAndLogError(error, 'submitMediaMessage');
      });
    }
  }


  private sendMsgError(error) {
    this.deviceLogService.sendError(error).subscribe(() => { });
  }


  async startClient(token: any): Promise<Client> {
    this.twilloCliente = await new Client(token);
    this.checkStatusClient();
    return this.twilloCliente;
  }

  getClient(): Client {
    if (!this.twilloCliente) {
      return;
    }
    return this.twilloCliente;
  }

  async startConversation(roomName: any): Promise<Conversation> {
    this.newConversation = await this.getClient().createConversation({ uniqueName: roomName }).catch((err: any) => this.sendAndLogError(err, 'startConversation'));
    this.conversationSubject$.next(this.newConversation);
    return this.newConversation;
  }

  async getConversationByRoomName(roomName: any): Promise<Conversation> {
    this.newConversation = await this.getClient().getConversationByUniqueName(roomName).catch((err: any) => this.sendAndLogError(err, 'getConversationByRoomName'));
    this.conversationSubject$.next(this.newConversation);
    return this.newConversation;
  }

  getConversation(): Conversation {
    if (!this.newConversation) {
      return;
    }
    return this.newConversation;
  }

  async joinConversation(): Promise<Conversation> {
    this.joinedConversation = await this.getConversation().join().catch((err: any) => this.sendAndLogError(err, 'joinConversation'));
    return this.joinedConversation;
  }

  checkStatusClient() {
    this.getClient().on('connectionStateChanged', async (state: any) => {
      if (state === 'denied') {
        this.sendAndLogError(state, 'connectionState denied');
      }
    });
  }

  async updateConversationSid(id) {
    this.getClient().on('conversationUpdated', (obj: any) => {
    });
    this.attendanceService.setConversatinSID(await this.getConversation().sid, id).subscribe(() => { },
        (error) => {
          this.sendAndLogError(error, 'updateConversationSid');
        });
  }

}
