import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  get(key): string {
    return window.localStorage.getItem(key);
  }

  set(key: string, value: string): string {
    window.localStorage.setItem(key, value);
    return value;
  }

  setSession(key: string, value: string): string {
    window.sessionStorage.setItem(key, value);
    return value;
  }

  getSession(key: string): string {
    return window.sessionStorage.getItem(key);
  }

  remove(key) {
    window.localStorage.removeItem(key);
  }

  clear() {
    window.localStorage.clear();
  }
}
