import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TenantService } from '../services/tenant.service';
import { SCHEDULE_PAGE } from '../app.constants';
import { StorageService } from '../services/storage.service';
import {ScheduleService} from '../services/schedule.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleGuard implements CanActivate {
  constructor(
    private tenantService: TenantService,
    private router: Router,
    private readonly storageService: StorageService,
    private readonly scheduleService: ScheduleService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.scheduleService.checkSchedule().subscribe((res: any) => {
      if (!res.online) {
        this.router.navigateByUrl(SCHEDULE_PAGE);
      }
    }, (error: any) => {
      console.log(error);
    });
    return true;
  }
}
