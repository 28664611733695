import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { STORAGE_KEYS } from '../app.constants';
import { ITenant, Tenant } from '../models/tenant';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class TermsService {

  protected static acceptTermsSubject: Subject<Boolean> = new Subject<Boolean>();

  protected static openTermsSubject: Subject<Boolean> = new Subject<Boolean>();

  constructor() {}

  public get onAcceptTerms() {
    return TermsService.acceptTermsSubject.asObservable();
  }

  public get onOpenTerms() {
    return TermsService.openTermsSubject.asObservable();
  }

  public notifyAcceptTerms(): void {
    TermsService.acceptTermsSubject.next(true);
  }

  public notifyOpenTerms(): void {
    TermsService.openTermsSubject.next(true);
  }

}
