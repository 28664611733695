import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private readonly toastController: ToastController,
    private readonly translateService: TranslateService
  ) {
    this.toastController.create({ animated: false }).then((t) => {
      t.present();
      t.dismiss();
    });
  }

  success(message: string, showIndefinitely?: boolean) {
    this.createToast(message, 'success', showIndefinitely);
  }

  error(message: string, showIndefinitely?: boolean) {
    this.createToast(message, 'danger', showIndefinitely);
  }

  info(message: string, showIndefinitely?: boolean) {
    this.createToast(message, 'secondary', showIndefinitely);
  }

  private async createToast(
    text: string,
    color: string,
    showIndefinitely?: boolean
  ) {
    const message = this.translateService.instant(text);

    const toast = await this.toastController.create({
      message,
      duration: showIndefinitely ? undefined : 3000,
      cssClass: 'toast-text',
      color
    });

    toast.present();
  }
}
