import {
    Injectable,
    RendererFactory2,
    ElementRef,
    Renderer2,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    connect,
    createLocalTracks,
    LocalAudioTrackPublication,
    Track,
    LocalVideoTrackPublication,
    createLocalAudioTrack,
} from 'twilio-video';
import { VIDEO_ROOM_PREFIX_SUPERVISOR } from '../app.constants';
import { DeviceLogService } from './device-log.service';

@Injectable({
    providedIn: 'root',
})
export class TwilioVideoService {
    localMediaContainer: ElementRef;
    remoteMediaContainer: ElementRef;

    private roomObj: any;

    private renderer: Renderer2;

    private roomSubject$ = new BehaviorSubject({});

    constructor(private readonly rendererFactory: RendererFactory2,
                private readonly deviceLogService: DeviceLogService) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    get roomSubject(): Observable<any> {
        return this.roomSubject$.asObservable();
    }

    async connectToRoom(accessToken: string, attendanceName) {
        if (this.roomObj) {
            return;
        }
        const localTracks = await createLocalTracks({ audio: true });
        
        try {
            this.roomObj = await connect(accessToken, {
                name: attendanceName,
                tracks: localTracks,
            });

            this.roomSubject$.next({
                action: `localParticipantConnected`,
            });

            this.startRoomSubscriber();
        } catch (err) {
            this.sendMsgError(err);
            console.error('connectToRoom', err.message);
        }
    }

    disconnectFromRoom() {
        if (this.roomObj) {
            this.roomObj.localParticipant.tracks.forEach((publication) => {
                this.unpublishLocalMediaTrack(publication);
            });

            this.roomObj.participants.forEach((participant) => participant.tracks.forEach((publication) => {
                if (publication.isSubscribed) {
                    const track = publication.track;
                    if (track) {
                        this.unsubscribeTrack(track);
                    }
                }
            }));

            this.roomObj.disconnect();
            this.roomObj = null;
        }
    }

    async startLocalTracks() {
        try {
            const audioTrackList = Array.from(
                this.roomObj.localParticipant.audioTracks.values()
            );

            const localTrack = (audioTrackList[0] as LocalAudioTrackPublication).track;
            this.attachLocalTrack(localTrack);
        } catch (err) {
            this.sendMsgError(err);
            console.error('startLocalTracks', err);
        }
    }

    private startRoomSubscriber() {
        this.roomObj.removeAllListeners();
        // if (!this.roomObj.participants.size) {
        this.roomObj.participants.forEach((participant) =>
            this.participantConnected(participant)
        );
        // }

        this.roomObj.on('participantConnected', (participant) =>
            this.participantConnected(participant)
        );

        this.roomObj.once('participantDisconnected', (participant) =>
            this.participantDisconnected(participant)
        );

        this.roomObj.on('disconnected', (room) => {
            room.localParticipant.tracks.forEach((publication) => {
                this.unpublishLocalMediaTrack(publication);
            });
        });

        this.roomObj.on('reconnecting', () => {
            this.participantReconnecting();
        });

    }

    private async participantConnected(participant) {
        if (!participant.identity.includes(VIDEO_ROOM_PREFIX_SUPERVISOR)) {
            await this.startLocalTracks();
            this.detachAllRemotesTracks();

            this.roomSubject$.next({
                action: 'participantConnected',
                name: participant.identity,
            });

            participant.tracks.forEach((publication) => {
                if (publication.isSubscribed) {
                    this.attachRemoteTrack(
                        this.renderer,
                        this.remoteMediaContainer,
                        publication.track
                    );
                }
            });

            participant.on('trackUnsubscribed', this.trackUnsubscribed);

            participant.on('trackSubscribed', (track) =>
                this.attachRemoteTrack(this.renderer, this.remoteMediaContainer, track)
            );
            participant.on('trackUnsubscribed', this.trackUnsubscribed);
            participant.on('trackRemoved', (track) => {
                this.unsubscribeTrack(track);
            });
        }
    }

    private participantDisconnected(participant) {
        if (!participant.identity.includes(VIDEO_ROOM_PREFIX_SUPERVISOR)) {
            this.detachAllRemotesTracks();
            this.roomSubject$.next({
                action: 'participantLeftSession',
                name: participant.identity,
            });
        }
    }

    private participantReconnecting() {
        this.roomSubject$.next({
            action: 'noInternetConnection'
        });
    }

    private attachLocalTrack(track) {
        const element = track.attach();
        this.renderer.data.id = track.sid;

        this.renderer.appendChild(this.localMediaContainer.nativeElement, element);
    }

    private attachRemoteTrack(
        renderer: Renderer2,
        remoteMediaContainer: ElementRef,
        track: Track
    ) {
        const element = track.attach();
        renderer.data.id = track.sid;
        renderer.setStyle(element, 'width', '100%');
        renderer.setStyle(element, 'height', '100%');
        renderer.addClass(element, 'live-video-local-participant');

        renderer.appendChild(remoteMediaContainer.nativeElement, element);
    }

    private detachAllRemotesTracks() {
        Array.from(
            this.remoteMediaContainer.nativeElement.children
        ).forEach((child) =>
            this.renderer.removeChild(this.remoteMediaContainer.nativeElement, child)
        );
    }

    private unsubscribeTrack(track) {
        track.detach().forEach((element) => {
            element.remove();
        });
    }

    // New Serices
    get roomSubjectAdmin(): Observable<any> {
        return this.roomSubject$.asObservable();
    }

    private trackUnsubscribed(track) {
        track
            .detach()
            .forEach((element) => element.remove());

    }

    private sendMsgError(error) {
        this.deviceLogService.sendError(error).subscribe(() => { });
    }

    muteUnMuteMicrophone(status) {
        this.roomObj.localParticipant.audioTracks.forEach(track => {
            if (status) {
                track.track.enable();
            } else {
                track.track.disable();
            }
        });
    }

    getLocalParticipantAudioTracks() {
        return this.roomObj.localParticipant.audioTracks;
    }

    unpublishLocalMediaTrack(localMediaTrack: LocalVideoTrackPublication | LocalAudioTrackPublication) {
        localMediaTrack.unpublish().track.stop().detach().forEach((element) => element.remove());
    }

    changeLocalParticipantAudioInputDevice(newDevice) {
        const [localMediaTrack] = this.getLocalParticipantAudioTracks().values();
        const trackIsDisabled = !localMediaTrack.track.isEnabled;
        this.unpublishLocalMediaTrack(localMediaTrack);
        createLocalAudioTrack({ deviceId: newDevice.deviceId }).then(async (track) => {
            await this.roomObj.localParticipant.publishTrack(track);
            if (trackIsDisabled) {
                track.disable();
            }
        });

    }
}
