import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ACCEPT_LANGUAGE } from '../app.constants';
import { StorageService } from '../services/storage.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {

  constructor(private readonly storageService: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const xtenantId = this.storageService.get('tenantId');
    const acceptLanguage = this.storageService.get('language');
    const traceId = uuidv4();
    let vpSessionId = this.storageService.getSession('vpSessionId');

    if (!vpSessionId) {
      vpSessionId = uuidv4();
      this.storageService.setSession('vpSessionId', vpSessionId);
    }

    req = req.clone({
      setHeaders: {
        'X-Trace-ID': `${traceId}`,
        'X-TenantID': `${xtenantId}`,
        'X-VP-SESSION-ID': `${vpSessionId}`,
        [ACCEPT_LANGUAGE]: acceptLanguage ? acceptLanguage : 'es',
        Accept: 'application/json'
      },
    });
    return next.handle(req);
  }
}
