import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading: any;
  isShowingLoader = false;

  loadingObserver: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingRequestMap: Map<string, boolean> = new Map<string, boolean>();

  constructor(
    private readonly loadingController: LoadingController,
    private readonly translateService: TranslateService
  ) {}

  async show(text?: string, duration?: number) {
    if (!this.isShowingLoader) {
      this.isShowingLoader = true;
      this.loading = await this.loadingController.create({
        message: text ? this.translateService.instant(text) : undefined,
        duration: duration ? duration : undefined,
        mode: 'ios',
        spinner: 'crescent'
      });
      await this.loading.present();
    }
  }

  /**
   * Dismiss all the pending loaders, if any
   */
  async dismiss() {
      await this.loadingController.dismiss();
      this.loading = null;
      this.isShowingLoader = false;
  }

  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided');
    }
    if (loading === true) {
      this.loadingRequestMap.set(url, loading);
      this.loadingObserver.next(true);
    } else if (loading === false && this.loadingRequestMap.has(url)) {
      this.loadingRequestMap.delete(url);
    }
    if (this.loadingRequestMap.size === 0) {
      this.loadingObserver.next(false);
    }
  }
}
