import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {IAttendance} from '../models/attendance';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  private resourceUrl = `${environment.API_BASE_URL}/schedule`;

  constructor(private http: HttpClient) { }

  checkSchedule() {
    return this.http.get<IAttendance[]>(`${this.resourceUrl}/check`);
  }
}
