import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AttendanceService} from './services/attendance.service';
import {TwilioVideoService} from './services/twilio-video.service';
import {ToastService} from './services/toast.service';
import {LoadingService} from './services/loading.service';
import {TermsPageModule} from './pages/terms/terms.module';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {TenantInterceptor} from './interceptors/tenant.interceptor';

import {TermsService} from './services/terms.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import {SharedModule} from './shared/shared.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        TermsPageModule,
        ZXingScannerModule,
        BackButtonDisableModule.forRoot({
            preserveScrollPosition: true
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        SharedModule,
    ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AttendanceService,
    TwilioVideoService,
    ToastService,
    LoadingService,
    TermsService,
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
