import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-vp-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})

export class ToolbarComponent implements OnInit {
  @Input() title: string;
  @Input() isReturnable = true;
  @Input() thirdControlButtonName: string;
  @Input() secondControlButtonName: string;
  @Output() firstButtonPressedEvent = new EventEmitter<any>();
  @Output() secondButtonPressedEvent = new EventEmitter<any>();
  @Output() thirdButtonPressedEvent = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  onFirstButtonPressed() {
    this.firstButtonPressedEvent.emit();
  }

  onSecondButtonPressed() {
    this.secondButtonPressedEvent.emit();
  }

  onThirdButtonPressed() {
    this.thirdButtonPressedEvent.emit();
  }

}
