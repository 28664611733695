<app-vp-toolbar [isReturnable]="false" [title]="'connectionFail.title'"></app-vp-toolbar>
<ion-content>
  <div class="no-network-container">
    <ion-item class="item-waiting-text">
      <ion-label class="position-text">
        {{'connectionFail.message' | translate }}
      </ion-label>
    </ion-item>
  </div>
</ion-content>

