/**
 * Constants of language app
 */
export const PORTUGUESE_LANGUAGE = 'pt-br';
export const ENGLISH_LANGUAGE = 'en';
export const ESPANHOL_LANGUAGE = 'es';
export const DEFAULT_LANGUAGE = PORTUGUESE_LANGUAGE;
export const SUPPORTED_LANGUAGES = [PORTUGUESE_LANGUAGE, ENGLISH_LANGUAGE,ESPANHOL_LANGUAGE];

/**
 * Constants of PAGES
 */
export const HOME_PAGE = '/home';
export const TERMS_AND_CONDITIONS_PAGE = '/terms-and-conditions';
export const PHONE_CALL_PAGE = '/phone-call';
export const LIVE_CHAT_PAGE = '/live-chat';
export const LIVE_VIDEO_PAGE = '/live-video';
export const CHATBOT_PURCHASE_PAGE = '/chatbot-purchase';
export const CHATBOT_SUPPORT_PAGE = '/chatbot-support';
export const PRODUCT_REGISTRATION_PAGE = '/product-registration';
export const INFORMATION_PAGE = '/information';
export const LIVE_VIDEO_DETAIL_PAGE = '/live-video-detail';
export const LIVE_CHAT_DETAIL_PAGE = '/live-chat-detail';
export const INIT_LIVE_CHAT_PAGE = '/init-live-chat';
export const INIT_LIVE_VIDEO_CHAT_PAGE = '/init-live-video-chat';
export const INIT_CHATBOT_PAGE = '/init-chatbot';
export const PRODUCT_DETAIL_PAGE = '/product-detail';
export const PRODUCT_COMPARE_PAGE = '/product-compare';
export const SPLASH_PAGE = '/splash';
export const SESSION_FEEDBACK_PAGE = '/session-feedback';
export const SCHEDULE_PAGE = '/schedule';

export const ATTENDANCE_TYPE = {
  VIDEO: 'VIDEO',
  CHAT: 'CHAT',
};

/**
 * Constants of types of user
 */
export const USER_TYPE = {
  CHATBOT: 'chatbot',
  USER: 'user',
  ATTENDANT: 'attendant'
};

export const VIDEO_ROOM_PREFIX_SUPERVISOR = "SUPERVISOR";

/**
 * Constants of Chat Page
 */
export const MAX_LENGTH_CHAT_TEXT = 2048;
export const MAX_LENGTH_CHATBOT_TEXT = 150;
export const TIMEOUT_INACTIVITY_CHAT = 300000;
export const TIMEOUT_INACTIVITY_CHAT_TOAST = 240000;
export const ATTENDANCE_NICKNAME_MIN_LENGTH = 3;
export const ATTENDANCE_NICKNAME_MAX_LENGTH = 50;

/**
 * Constants of Video Page
 */
export const TIMEOUT_VIDEO = 3600000;
export const TIMEOUT_VIDEO_TOAST = 1800000;
export const TIMEOUT_INACTIVITY_VIDEO = 300000;


export const CHAT_MESSAGE_FONT_SIZE = {
  MAX: 1.4,
  MIN: 0.9,
};

export const CHATBOT_INITIAL_MESSAGES = [
  'chatbot.messages.chatbotInit1',
  'chatbot.messages.chatbotInit2',
  'chatbot.messages.chooseProduct',
  'chatbot.messages.chooseProductModel',
];


/**
 * Constants of headers
 */
export const HEADER_KEY_TOKEN = 'x-watson-session';
export const ACCEPT_LANGUAGE = 'Accept-Language';

/**
 * Constants of PAGES
 */
export const CHAT_PAGE = '/chat-page';
export const INIT_CHAT_PAGE = '/init-chat';
export const LAST_ATTENDANCES_PAGE = '/last-attendance';

/**
 * Constants of API Routes
 */
export const CONVERSATION_HISTORY_API = '/conversation-history';
export const AUTHENTICATION_API = '/authenticate';


/**
 * Constants of storage
 */
export const PRODUCT_SERIAL = 'product-serial';
export const PRODUCT_PREFIX = 'product-prefix';
export const AUTHENTICATION_TOKEN = 'authenticationToken';

/**
 * Constants of network
 */
export const ONLINE = 'online';
export const OFFLINE = 'offline';
export const CONNECTION_STATUS = 'connectionStatus';


export const MESSAGES_DEFAULT_CHATBOT = {
  WELCOME: ['chat.messages.chatbotInit1', 'chat.messages.chatbotInit2', 'chat.messages.chatbotInit3'],
  ERROR: ['chat.messages.chatbotFailure', 'chat.messages.chatbotFailure2', 'chat.messages.chatbotFailure3']
};

export const STORAGE_KEYS = {
  ACCEPTED_TERMS: 'acceptedTerms',
  TENANT_ID: 'tenantId',
  LANGUAGE: 'language',
  TERMS: 'terms',
  TERMS_SELECT: 'terms-select',
  COUNTRY_ISO_CODE: 'countryIsoCode',
  UTM_SOURCE: 'utm_source',
  PRIVACY_POLICY: 'privacyPolicy',
  DATA_PRIVACY: 'dataPrivacy',
  COOKIES: 'cookies',
  TENANT_NOT_FOUND: 'TENANT_NOT_FOUND',
  CLIENT_RANDOM_IDENTITY: 'clientRandomIdentity'
};

export const PRODUCT = {
  MIN_LENGTH_MODEL_REF: 10,
  MAX_LENGTH_MODEL_REF: 11
};

export const ADS_TYPES = {
  IMAGE: 1,
  YOUTUBE: 2,
  VIDEO: 3
};

/**
 * Constants of Audio Alert
 */
export const AUDIO_RECEIVE_MESSAGE = '../assets/audio/receive-message.wav';

/**
 * Constants of product errors
 */

export const INVALID_SERIAL = 'error.invalid-serial';

/**
 * Constants for youtube links
 */
export const YOUTUBE_URLS = {
  YOUTUBE_COM: 'youtube.com',
  YOUTU_BE: 'youtu.be',
  YOUTUBE_NOCOOKIE: 'youtube-nocookie.com'
}

export const UTM_SOURCE_MAX_LENGTH = 100;
export const ATTENDANCE_ID_STORAGE_KEY = 'ATTEDANCE_ID';

/**
 * Constants of platform
 */
export const DESKTOP_VALUE = 'desktop';
export const PLATFORM_IOS_VALUE = 'ios';