import {Component, OnInit} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateService} from '@ngx-translate/core';
import {ATTENDANCE_ID_STORAGE_KEY, ENGLISH_LANGUAGE, HOME_PAGE, SCHEDULE_PAGE, STORAGE_KEYS, SUPPORTED_LANGUAGES} from './app.constants';
import {StorageService} from './services/storage.service';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {fromEvent, merge, Observable, Observer} from 'rxjs';
import {map} from 'rxjs/operators';
import {AttendanceService} from './services/attendance.service';
import {TwilioVideoService} from './services/twilio-video.service';
import {TwilioChatService} from './services/twilio-chat.service';
import { DisableBackService } from './services/disable-back.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  keyRoomName = 'VIDEO_ROOM_NAME_KEY';
  keytoken = 'VIDEO_ROOM_TOKEN_KEY';
  chatKeyRoomName = 'CHAT_ROOM_NAME_KEY';
  chatKeytoken = 'CHAT_ROOM_TOKEN_KEY';
  isOnline = true;

  constructor(
      private readonly platform: Platform,
      private readonly splashScreen: SplashScreen,
      private readonly statusBar: StatusBar,
      private readonly translate: TranslateService,
      private readonly storageService: StorageService,
      private readonly router: Router,
      private readonly modalController: ModalController,
      private readonly attendanceService: AttendanceService,
      private readonly twilioVideoService: TwilioVideoService,
      private readonly twilioChatService: TwilioChatService,
      private readonly disableBackService: DisableBackService
  ) {
    this.accepted = Boolean(
        this.storageService.get(STORAGE_KEYS.ACCEPTED_TERMS)
    );
    this.createOnline$().subscribe(isOnline => {
      modalController.getTop().then((res: any) => {
        if (res) {
          modalController.dismiss();
        }
      });
      if (!isOnline) {
        this.isOnline = false;
      } else if (!this.isOnline) {
        this.twilioChatService.leave();
        this.twilioVideoService.disconnectFromRoom();
        this.disableBackService.enable();
        this.router.navigateByUrl(HOME_PAGE);
        this.isOnline = true;
        this.clearStorage();
        const attendanceId = localStorage.getItem(ATTENDANCE_ID_STORAGE_KEY);
        this.attendanceService.finishAttendance(attendanceId).subscribe(() => {
          localStorage.removeItem(ATTENDANCE_ID_STORAGE_KEY);
        });
      } else {
        this.isOnline = true;
      }
    });
    this.storageService.set('APP_VERSION', environment.VERSION);
    this.initializeApp();
  }

  accepted = false;

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.configureTranslation();
      this.initTranslate();
    });
  }

  private configureTranslation() {
    this.translate.addLangs(SUPPORTED_LANGUAGES);
    this.translate.setDefaultLang(ENGLISH_LANGUAGE);
  }

  initTranslate() {
    this.translate.use(
        this.storageService.get(STORAGE_KEYS.LANGUAGE) || ENGLISH_LANGUAGE
    );
  }

  ngOnInit() {
    const navigateToHome = sessionStorage.getItem('navigateToHome');

    if (navigateToHome === 'true') {
      this.router.navigateByUrl(HOME_PAGE);
      localStorage.removeItem('navigateToHome');
    }
  }

  createOnline$() {
    return merge<boolean>(
        fromEvent(window, 'offline').pipe(map(() => false)),
        fromEvent(window, 'online').pipe(map(() => true)),
        new Observable((sub: Observer<boolean>) => {
          sub.next(navigator.onLine);
          sub.complete();
        }));
  }

  clearStorage() {
    localStorage.removeItem('clientUsername');
    localStorage.removeItem(this.chatKeyRoomName);
    localStorage.removeItem(this.chatKeytoken);
    localStorage.removeItem(this.keyRoomName);
    localStorage.removeItem(this.keytoken);
  }

}
