import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class DisableBackService {
    disableBackSubject = new BehaviorSubject<boolean>(false);
    disableBack$ = this.disableBackSubject.asObservable();

    constructor() {}

    disable(): void {
        this.disableBackSubject.next(true);
    }

    enable(): void {
        this.disableBackSubject.next(false);
    }
}