import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DeviceLogService {

    private resourceUrl = `${environment.API_BASE_URL}/mobile-log`;

    private attendanceIdStorage = 'ATTEDANCE_ID';

    constructor(private http: HttpClient, private deviceService: DeviceDetectorService) { }

    init(attendanceId, source = null): Observable<any> {
        const value = {
            info: JSON.stringify(this.deviceService.getDeviceInfo()),
            os: this.deviceService.os,
            device: this.deviceService.device,
            browser: this.deviceService.browser,
            browserVersion: this.deviceService.browser_version,
            attendanceId,
            source
        };

        return this.http.post<any>(this.resourceUrl, value);
    }

    sendError(error): Observable<any>{
        return this.http.put<any>(`${this.resourceUrl}/attedance/${localStorage.getItem(this.attendanceIdStorage)}`, {logError: JSON.stringify(error)});
    }
}
