import { CameraComponent } from './modal/camera/camera.component';
import { WaitingTheAttendantModalComponent } from './modal/waiting-the-attendant-modal/waiting-the-attendant-modal.component';
import { NgModule } from '@angular/core';
import { AttendanceCardComponent } from './components/attendance-card/attendance-card.component';
import { ChangeTextSizeComponent } from './components/change-text-size/change-text-size.component';
import { DatasheetViewItemComponent } from './components/datasheet-view-item/datasheet-view-item.component';
import { DatasheetViewComponent } from './components/datasheet-view/datasheet-view.component';
import { HeaderComponent } from './components/header/header.component';
import {MessagesViewComponent} from './components/messages-view/messages-view.component';
import {SharedLibsModule} from './shared-libs.module';
import {ValidateChatInputDirective} from './directives/validate-chat-input.directive';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {NgxYoutubePlayerModule} from 'ngx-youtube-player'
import {NoNetworkDetectedModalComponent} from './modal/no-network-detected-modal/no-network-detected-modal.component';
import {AdvertisingContentComponent} from './components/advertising-content/advertising-content.component';

@NgModule({
  declarations: [
    AttendanceCardComponent,
    ChangeTextSizeComponent,
    HeaderComponent,
    ToolbarComponent,
    MessagesViewComponent,
    DatasheetViewComponent,
    DatasheetViewItemComponent,
    ValidateChatInputDirective,
    WaitingTheAttendantModalComponent,
    NoNetworkDetectedModalComponent,
    CameraComponent,
    AdvertisingContentComponent
  ],
  exports: [
    AttendanceCardComponent,
    ChangeTextSizeComponent,
    HeaderComponent,
    ToolbarComponent,
    MessagesViewComponent,
    DatasheetViewComponent,
    DatasheetViewItemComponent,
    ValidateChatInputDirective,
    WaitingTheAttendantModalComponent,
    NoNetworkDetectedModalComponent,
    CameraComponent,
    AdvertisingContentComponent
  ],
  imports: [SharedLibsModule, NgxYoutubePlayerModule.forRoot()],
  providers: [],
  entryComponents: [],
})
export class SharedModule {
}
