<app-vp-toolbar [title]="'terms.title'" [isReturnable]="false"></app-vp-toolbar>
<ion-content [class.hide-terms]="accepted">
  <ion-grid>
    <ion-row class="ion-text-center">
      <ion-col>
        <img class="logo" src="../../../assets/logo/epson-logo-original.svg" alt="Epson logo">
      </ion-col>
    </ion-row>

    <ion-row class="ion-text-center">
      <ion-col>

        <div class="info-card">
          <ion-row class="ion-text-center ion-no-padding ion-no-margin">
            <ion-col class="ion-no-padding ion-no-margin">
              <div class="info-subtitle">
                <label>{{'terms.subtitle' | translate}}</label>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="ion-no-padding ion-no-margin">
            <ion-col class="ion-no-padding ion-no-margin">
              <div class="term-item" [ngClass]="{'term-disabled' : !isTermsOfService}">
                <input type="checkbox" id="termsOfService" name="termsOfService"
                       [checked]="isTermsOfService" (change)="isTermsOfService = !isTermsOfService">
                <label>{{'terms.checkboxTermsOfUseLabel' | translate}}</label><a [href]="tenant?.termsOfService"
                                                                                 target="_blank">
                {{'terms.termsOfUseLabel' | translate }}
              </a>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="ion-no-padding ion-no-margin">
            <ion-col class="ion-no-padding ion-no-margin">
              <div class="term-item" [ngClass]="{'term-disabled' : !isPrivacyPolicy}">
                <input type="checkbox" id="privacyPolicy" name="privacyPolicy"
                       [checked]="isPrivacyPolicy" (change)="isPrivacyPolicy = !isPrivacyPolicy">
                <label>{{'terms.checkboxLabel' | translate}}</label><a [href]="tenant?.privacyPolicy" target="_blank">
                {{'terms.privacyPolicyLabel' | translate }}
              </a>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="ion-no-padding ion-no-margin">
            <ion-col class="ion-no-padding ion-no-margin">
              <div class="term-item" *ngIf="tenant?.dataPrivacy" [ngClass]="{'term-disabled' : !isDataPrivacy}">
                <input type="checkbox" id="dataPrivacy" name="dataPrivacy"
                       [checked]="isDataPrivacy" (change)="isDataPrivacy = !isDataPrivacy">
                <label>{{'terms.checkboxLabel' | translate}}</label><a [href]="tenant?.dataPrivacy" target="_blank">
                {{'terms.dataPrivacyLabel' | translate }}
              </a>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="ion-no-padding ion-no-margin">
            <ion-col class="ion-no-padding ion-no-margin">
              <div class="term-item" [ngClass]="{'term-disabled' : !enableAcceptButton()}">
                <input type="checkbox" id="acceptAll" name="acceptAll"
                       [checked]="enableAcceptButton()" (change)="onAcceptAll()">
                <label for="acceptAll">{{'terms.checkboxAcceptAllLabel' | translate}}</label>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="ion-no-padding ion-no-margin">
            <ion-col class="ion-no-padding ion-no-margin">
              <ion-button *ngIf="!buttonRejected" class="medium-button red-gradient-1"
                          (click)="decline()">{{'terms.buttonReject' | translate }}</ion-button>
            </ion-col>
            <ion-col class="ion-no-padding ion-no-margin">
              <ion-button class="medium-button" [ngClass]="{'disabled-1' : !enableAcceptButton()}"
                          (click)="accept()">{{'terms.buttonAccept' | translate }}</ion-button>
            </ion-col>
          </ion-row>
        </div>

      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>
