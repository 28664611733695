import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-network-detected-modal',
  templateUrl: './no-network-detected-modal.component.html',
  styleUrls: ['./no-network-detected-modal.component.scss'],
})
export class NoNetworkDetectedModalComponent implements OnInit {

  constructor() {}

  ngOnInit() {

  }


}
